export const URL_LOGIN = 'api/Ms/loginob';
export const URL_VALIDATE_SESSION = 'api/Ms/loginob';
export const URL_USER_INFO_NBE = 'api/Ms/nbeuserinfo';
export const URL_USER_INFO_NBP = 'api/Ms/nbpuserinfo';
export const URL_DECRYPT_URL_PARAMS = 'api/Ms/decrypturl';
export const URL_REJECT = 'api/Ms/rejectob';
export const URL_CONCLUDE = 'api/Ms/concludeob';
export const URL_EXPIRE = 'api/Ms/expireob';
export const URL_CLOSE = 'api/Ms/closeob';


// OTP
export const URL_GENERATE_OTP = 'api/Ms/generateOTP';
export const URL_VALIDATE_OTP = 'api/Ms/validateOTP';

// captcha
export const URL_CAPTCHA = 'api/Ms/validateCaptcha';
export const URL_BRANCH = 'https://branchlocator.santander.com/?view=pt&defaultLanguage=pt';
export const URL_CONTACTE_NOS = 'https://www.santandertotta.pt/pt_PT/Particulares/contactos-santander.html';

// TRX
export const URL_INVOKE_OA02 = 'api/Ms/invokeOA02';
export const URL_INVOKE_OA03 = 'api/Ms/invokeOA03';
export const URL_INVOKE_OA12 = 'api/Ms/invokeOA12';
export const URL_INVOKE_OA13 = 'api/Ms/invokeOA13';

// C2C
export const URL_INITIATE_C2C = 'api/Ms/initiateCall';
export const URL_VALIDATE_C2C = 'api/Ms/validateCall';

// Tokens
export const URL_REFRESH_TOKEN = 'api/Ms/refreshtoken';

// App
export const URL_DECRYPT_API_URL_PARAMS = 'api/Ms/decryptAppUrl';

export const OTP_SIM = '123456';

export const POSIBLE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
export const LENGTH_CODE = 10;

// Simulador

export const URL_SIBSCALLER_AVAILABLE = 'api/Ms/sibsCaller';

// Link Preçario

export const URL_LINK_PRECARIO = 'https://www.santander.pt/content/dam/sitepublico/pdfs/pdfs-empresas/contas/BST-IE-50003225.pdf ' ;

// Import Lotes

export const URL_INVOKE_IMPORT = 'api/Ms/importLote';
export const URL_INVOKE_SUBMIT = 'api/Ms/submitLote';
export const URL_INVOKE_PROGRESS = 'api/Ms/verifyImportProgress';
export const URL_INVOKE_SUBMIT_PROGRESS = 'api/Ms/verifySubmitProgress';

export const URL_INVOKE_DETAILS_LOTES = 'api/Ms/getLoteDetails';


// List OTP

export const URL_LIST_OTP = 'api/Ms/nbpuserinfo';
export const URL_SELECTION_OTP = 'api/Ms/validatephone';

export const DEFAULT_ENTITY = '10095';

export const VERSION_4 = 'v1-0-4';
