import { Injectable } from '@angular/core';
import { TYPE_TRF_SEPA, TYPE_TRF_IPS, TYPE_TRF_SEPA_SCHEDULE, TYPE_TRF_IPS_SCHEDULE, TYPE_CANCEL_PAG_SERVICOS_PONTUAL, TYPE_CANCEL_PAG_ESTADO_PONTUAL, TYPE_CANCEL_CARREGAMENTOS_PONTUAL, TYPE_CANCEL_TSU_PONTUAL, TYPE_CANCEL_TARGET } from 'src/app/shared/global';
import { TYPE_TRF_SEPA_PLAN, TYPE_TRF_IPS_PLAN, TYPE_TRF_SEPA_URG, TYPE_TRF_SWIFT, TYPE_PAG_SERVICOS } from 'src/app/shared/global';
import { TYPE_TRF_TARGET, TYPE_PAG_STATE, TYPE_PAG_SERVICOS_AGENDADOS, TYPE_PAG_STATE_AGENDADOS } from 'src/app/shared/global';
import { TYPE_PAG_CARREGAMENTOS, TYPE_PAG_CARREGAMENTOS_AGENDADOS, TYPE_CANCEL_SEPA, TYPE_CANCEL_IPS } from 'src/app/shared/global';
import { TYPE_CANCEL_SEPA_PERIODIC, TYPE_CANCEL_IPS_PERIODIC, TYPE_CANCEL_SERVICOS, TYPE_CANCEL_ESTADO } from 'src/app/shared/global';
import { TYPE_CANCEL_LOTE_SEPA, TYPE_LOTE_IPS, TYPE_CANCEL_LOTE_IPS, TYPE_LOTE_TARGET } from 'src/app/shared/global';
import { TYPE_SEG_SOCIAL_SCHEDULE, TYPE_SEG_SOCIAL, TYPE_CANCEL_SEG_SOCIAL_SCHEDULE, TYPE_CANCEL_LOTE_SWIFT, TYPE_CANCEL_SEPA_PONTUAL, TYPE_CANCEL_IPS_PONTUAL, TYPE_CANCEL_SWIFT_PONTUAL, TYPE_CANCEL_TARGET_PONTUAL } from '../../shared/global';
import { TYPE_CANCEL_CARREGAMENTOS, TYPE_LOTE_SEPA, TYPE_LOTE_SWIFT, TYPE_CANCEL_LOTE_TARGET } from 'src/app/shared/global';
import { TYPE_PAYMENTS_TSU, CANCEL_PAYMENTS_TSU, TYPE_PAYMENTS_FUTURE_TSU} from 'src/app/shared/global';
import { TYPE_LOTE_PAG_SERVICOS, TYPE_CANCEL_LOTE_PAG_SERVICOS } from '../../shared/global';
import { TYPE_CANCEL_LOTE_PAG_ESTADO, TYPE_LOTE_PAG_ESTADO} from '../../shared/global';

@Injectable({
  providedIn: 'root'
})
export class PaymentBusinessService {

  constructor() { }

  isTransferOperation(operationType: string) {
    return (operationType === TYPE_TRF_SEPA
      || operationType === TYPE_TRF_IPS
      || operationType === TYPE_TRF_SEPA_SCHEDULE
      || operationType === TYPE_TRF_IPS_SCHEDULE
      || operationType === TYPE_TRF_SEPA_PLAN
      || operationType === TYPE_TRF_IPS_PLAN
      || operationType === TYPE_TRF_SEPA_URG
      || operationType === TYPE_TRF_SWIFT
      || operationType === TYPE_TRF_TARGET);
  }

  isPaymentOperation(operationType: string) {
    return (operationType === TYPE_PAG_SERVICOS
      || operationType === TYPE_PAG_STATE
      || operationType === TYPE_PAG_SERVICOS_AGENDADOS
      || operationType === TYPE_PAG_STATE_AGENDADOS
      || operationType === TYPE_SEG_SOCIAL
      || operationType === TYPE_SEG_SOCIAL_SCHEDULE
      || operationType === TYPE_PAYMENTS_TSU
      || operationType === TYPE_PAYMENTS_FUTURE_TSU);
  }

  isChargingOperation(operationType: string) {
    return (operationType === TYPE_PAG_CARREGAMENTOS
      || operationType === TYPE_PAG_CARREGAMENTOS_AGENDADOS);
  }

  isCancelOperation(operationType: string) {
    return operationType === TYPE_CANCEL_SEPA || operationType === TYPE_CANCEL_IPS
                                              || operationType === TYPE_CANCEL_SEPA_PERIODIC
                                              || operationType === TYPE_CANCEL_IPS_PERIODIC
                                              || operationType === TYPE_CANCEL_SERVICOS
                                              || operationType === TYPE_CANCEL_CARREGAMENTOS
                                              || operationType === TYPE_CANCEL_ESTADO
                                              || operationType === TYPE_CANCEL_SEG_SOCIAL_SCHEDULE
                                              || operationType === CANCEL_PAYMENTS_TSU
                                              || operationType === TYPE_CANCEL_SEPA_PONTUAL
                                              || operationType === TYPE_CANCEL_IPS_PONTUAL
                                              || operationType === TYPE_CANCEL_SWIFT_PONTUAL
                                              || operationType === TYPE_CANCEL_TARGET_PONTUAL
                                              || operationType === TYPE_CANCEL_PAG_SERVICOS_PONTUAL
                                              || operationType === TYPE_CANCEL_PAG_ESTADO_PONTUAL
                                              || operationType === TYPE_CANCEL_CARREGAMENTOS_PONTUAL
                                              || operationType === TYPE_CANCEL_TSU_PONTUAL
                                              || operationType === TYPE_CANCEL_TARGET;
  }

  isCancelTransferOperation(operationType: string) {
    return operationType === TYPE_CANCEL_SEPA || operationType === TYPE_CANCEL_IPS
                                            || operationType === TYPE_CANCEL_SEPA_PERIODIC
                                            || operationType === TYPE_CANCEL_IPS_PERIODIC
                                            || operationType === TYPE_CANCEL_SEPA_PONTUAL
                                            || operationType === TYPE_CANCEL_IPS_PONTUAL
                                            || operationType === TYPE_CANCEL_TARGET_PONTUAL
                                            || operationType === TYPE_CANCEL_SWIFT_PONTUAL
                                            || operationType === TYPE_CANCEL_TARGET;
  }

  isCancelPaymentOperation(operationType: string) {
    return operationType === TYPE_CANCEL_SERVICOS || operationType === TYPE_CANCEL_CARREGAMENTOS
                                              || operationType === TYPE_CANCEL_ESTADO
                                              || operationType === TYPE_CANCEL_SEG_SOCIAL_SCHEDULE
                                              || operationType === CANCEL_PAYMENTS_TSU
                                              || operationType === TYPE_CANCEL_PAG_SERVICOS_PONTUAL
                                              || operationType === TYPE_CANCEL_PAG_ESTADO_PONTUAL
                                              || operationType === TYPE_CANCEL_CARREGAMENTOS_PONTUAL
                                              || operationType === TYPE_CANCEL_TSU_PONTUAL;
  }

  isLoteOperation(operationType: string) {
    return this.isLoteTrfOperation(operationType)
    || this.isLotePaymentOperation(operationType);
  }

  isLoteCancelOperation(operationType: string) {
    return this.isLoteCancelTrfOperation(operationType)
    || this.isLoteCancelPaymentOperation(operationType);
  }

  isLoteTrfOperation(operationType: string) {
    return operationType === TYPE_LOTE_SEPA
    || operationType === TYPE_CANCEL_LOTE_SEPA
    || operationType === TYPE_LOTE_SWIFT
    || operationType === TYPE_LOTE_IPS
    || operationType === TYPE_CANCEL_LOTE_IPS
    || operationType === TYPE_LOTE_TARGET
    || operationType === TYPE_CANCEL_LOTE_TARGET
    || operationType === TYPE_CANCEL_LOTE_SWIFT;
  }

  isLoteCancelTrfOperation(operationType: string) {
    return operationType === TYPE_CANCEL_LOTE_SEPA
    || operationType === TYPE_CANCEL_LOTE_IPS
    || operationType === TYPE_CANCEL_LOTE_TARGET
    || operationType === TYPE_CANCEL_LOTE_SWIFT;
  }

  isLotePaymentOperation(operationType: string) {
    return operationType === TYPE_LOTE_PAG_SERVICOS
      || operationType === TYPE_LOTE_PAG_ESTADO
      || operationType === TYPE_CANCEL_LOTE_PAG_SERVICOS
      || operationType === TYPE_CANCEL_LOTE_PAG_ESTADO;
  }

  isLoteCancelPaymentOperation(operationType: string) {
    return operationType === TYPE_CANCEL_LOTE_PAG_SERVICOS
    || operationType === TYPE_CANCEL_LOTE_PAG_ESTADO;
  }
}
